<template>
	<section class="pa-2 agent-portal">
		<v-row justify="center" align="center">
			<v-col cols="12">
				<v-card flat tile elevation-0 class="transparent">
					<v-card-text>
						<v-row>
							<v-col cols="12" sm="10" class="px-0">
								<Autocomplete
									@searched_address="
										(val) => (form_data.searched_address = val)
									"
								></Autocomplete>
							</v-col>
							<v-col cols="12" sm="2" class="px-0">
								<v-btn
									style="height: 100%"
									color="primary"
									depressed
									block
									tile
									@click="
										form_data.searched_address ? createNewApplication() : null
									"
									>Create Quote</v-btn
								>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row justify="center" align="center">
			<v-col cols="12">
				<v-card flat tile elevation-0 class="transparent-bg">
					<template>
						<v-card-title class="px-0 mx-0" style="border-top: solid black 1px">
							<v-spacer></v-spacer>
							<h1 class="font-weight-regular text-capitalize">
								Customer Search
							</h1>
							<v-spacer></v-spacer>
						</v-card-title>
						<v-card-text>
							<div class="ma-2">
								<FormMaker
									:col="{ cols: 12 }"
									:model="applicationFilters"
									:schema="filtersFormStructure"
								/>
							</div>
						</v-card-text>
					</template>
				</v-card>
			</v-col>
		</v-row>
		<v-row justify="center" align="center">
			<v-col cols="12">
				<ApplicationsListing
					:agent_applications_list.sync="customFilteredApplications"
					:agent_applications_list_pagination.sync="
						agent_applications_list_pagination
					"
					@reloadApplications="reloadApplications()"
				/>
			</v-col>
		</v-row>

		<bulkUploadActionBtn @reloadApplications="reloadApplications()" />
	</section>
</template>

<script>
	import { debounce } from "lodash";
	import { mapState } from "vuex";

	import form_data from "@/plugins/data.json";
	import FormMaker from "@/components/FormMaker";
	import Autocomplete from "@/components/address-autocomplete/Autocomplete";
	import ApplicationsListing from "@/components/tables/ApplicationsListing";
	import bulkUploadActionBtn from "@/components/action-btn/bulkUploadActionBtn.vue";

	export default {
		beforeRouteEnter(to, from, next) {
			next((vm) => {
				vm.$store.state.application.form_data = form_data;
				vm.$store.state.application.application = {};
				vm.form_data.searched_address = null;
				if (vm.currentUserRole == "admin") {
					vm.applicationFilters.rocket_agent = vm.allAgentsList[0];
					vm.$store.state.selected_agent_id = null;
				} else {
					vm.applicationFilters.rocket_agent = vm.currentUser?.agent;
					vm.$store.state.selected_agent_id = vm.currentUser.agent.id;
				}
				vm.getAgentApplicationsListDebounced();
			});
		},
		beforeRouteUpdate(to, from, next) {
			next((vm) => {
				vm.$store.state.application.form_data = form_data;
				vm.$store.state.application.application = {};
				vm.form_data.searched_address = null;
				if (vm.currentUserRole == "admin") {
					vm.applicationFilters.rocket_agent = vm.allAgentsList[0];
					vm.$store.state.selected_agent_id = null;
				} else {
					vm.applicationFilters.rocket_agent = vm.currentUser?.agent;
					vm.$store.state.selected_agent_id = vm.currentUser.agent.id;
				}
				vm.getAgentApplicationsListDebounced();
			});
		},
		components: {
			FormMaker,
			ApplicationsListing,
			Autocomplete,
			bulkUploadActionBtn,
		},
		data() {
			return {
				applicationFilters: {
					address: null,
					application_id: null,
					rocket_agent: this.currentUser?.agent,
					client_name_id: null,
					alerts: null,
				},
			};
		},
		computed: {
			...mapState({
				form_data: (state) => state.application.form_data,
				application: (state) => state.application.application,
				agent_applications_list: (state) =>
					state.application.agent_applications_list,
				agent_applications_list_pagination: (state) =>
					state.application.agent_applications_list_pagination,
			}),
			allAgentsList() {
				let agents_list = this.$store.getters["assignableAgentLists"];
				let allAgentsList = [
					{
						id: null,
						name: "All",
						agent: {
							agent_id: "All",
							id: null,
							user_id: null,
							payload: null,
						},
					},
				];
				allAgentsList = allAgentsList.concat(agents_list);
				return allAgentsList;
			},
			customFilteredApplications() {
				// this.getAgentApplicationsListDebounced();

				let filteredApplications = this.agent_applications_list.filter(
					(application) => {
						if (this.applicationFilters?.alerts === null) {
							return true;
						}

						let alertColor =
							this.$helpers.getApplicationAlertDotColor(application);

						if (!alertColor) {
							return false;
						}

						if (this.applicationFilters.alerts == "All Alerts") {
							return !!alertColor;
						}

						return !!this.applicationFilters.alerts
							.toString()
							.toLowerCase()
							.includes(alertColor.toString().toLowerCase());
					}
				);

				return filteredApplications;
			},
			filtersFormStructure() {
				return {
					address: {
						type: "text",
						name: "address",
						label: "Address",
						clearable: true,
						outlined: true,
						solo: true,
						flat: true,
						hideDetails: true,
						color: "primary",
						elevation: 0,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [],
					},
					application_id: {
						type: "text",
						name: "application_id",
						label: "Application Id",
						clearable: true,
						outlined: true,
						solo: true,
						flat: true,
						hideDetails: true,
						color: "primary",
						elevation: 0,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [],
					},
					rocket_agent: {
						hidden: this.currentUserAccesses?.can_see_others_applications == "No",
						type: "autocomplete",
						name: "rocket_agent",
						label: "Rocket Agent",
						returnObject: true,
						clearable: false,
						outlined: true,
						solo: true,
						flat: true,
						hideDetails: true,
						color: "primary",
						elevation: 0,
						items: this.allAgentsList,
						itemText: "name",
						itemValue: "agent.id",
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [],
					},
					client_name_id: {
						type: "text",
						name: "client_name_id",
						label: "Insured Name",
						clearable: true,
						outlined: true,
						solo: true,
						flat: true,
						hideDetails: true,
						color: "primary",
						elevation: 0,
						col: { cols: 12, sm: 12, md: 6, lg: 6, xl: 6 },
						rules: [],
					},
					alerts: {
						type: "autocomplete",
						name: "alerts",
						label: "Alerts",
						clearable: true,
						outlined: true,
						solo: true,
						flat: true,
						hideDetails: true,
						color: "primary",
						elevation: 0,
						items: [
							"All Alerts",
							"Red - lead missing critical data, could not auto-launch",
							"Orange - could not send quote, email/phone number was invalid",
							"Yellow - quotes sent via “time-out job” instead of “completed”",
							"Teal - Home quotes “Not-Sent”",
							"Purple - All Other Alerts",
						],
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
						rules: [],
					},
				};
			},
		},
		watch: {
			// applicationFilters: {
			// 	handler: function(newVal, oldVal) {
			// 		console.log(newVal, oldVal);
			// 		if (newVal?.alerts == oldVal?.alerts) return;
			// 		this.applicationFiltersDebounced();
			// 	},
			// 	deep: true
			// },
			"applicationFilters.address"() {
				this.applicationFiltersDebounced();
			},
			"applicationFilters.application_id"() {
				this.applicationFiltersDebounced();
			},
			"applicationFilters.rocket_agent"() {
				this.applicationFiltersDebounced();
			},
			"applicationFilters.client_name_id"() {
				this.applicationFiltersDebounced();
			},
		},
		created() {},
		methods: {
			applicationFiltersDebounced: debounce(
				function () {
					// console.log(this.applicationFilters?.rocket_agent);
					this.$store.state.selected_agent_id =
						this.applicationFilters?.rocket_agent?.agent?.id;
					this.getAgentApplicationsListDebounced();
				},
				300,
				{ leading: true, trailing: true }
			),
			getAgentApplicationsListDebounced: debounce(
				function () {
					let filters = this.applicationFilters;
					this.$store.dispatch("application/getAgentApplicationsList", {
						filters,
					});
				},
				290,
				{ leading: true, trailing: false }
			),
			reloadApplications() {
				this.getAgentApplicationsListDebounced();
				this.$store.commit("setLoading", false);
			},
			autocompleteAddressUpdated(addressData, placeResultData, id) {
				this.form_data.searched_address = placeResultData.formatted_address;
				this.form_data.address = this.form_data.searched_address;
			},
			async createNewApplication() {
				let data = {
					agent_id: this.currentUser?.agent?.id,
					address: this.form_data.searched_address,
				};
				return await this.post(
					`${this.baseUrl}/agent-portal/create-new-application`,
					data,
					true
				).then((res) => {
					if (res && res.code == 200) {
						let application = res.data.application;
						this.$router.push({
							name: "application-details",
							params: { application_id: application.application_id },
						});
					}
					return res;
				});
			},
		},
	};
</script>

<style lang="scss">
	@import "src/assets/variables.scss";
</style>
