const inputFields = [
	{
		fieldName: "address1",
		fieldLabel: "Address 1",
	},
	{
		fieldName: "address2",
		fieldLabel: "Address 2",
	},
	{
		fieldName: "city",
		fieldLabel: "City",
	},
	{
		fieldName: "state",
		fieldLabel: "State",
	},
	{
		fieldName: "zipCode",
		fieldLabel: "ZIP Code",
	},
];

export default inputFields;